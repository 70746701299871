import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { UserType } from 'enums/user-type.enum';
import { RoomParticipant } from 'types/room-participant';
import { API_URL } from 'constants/config';
import { getClassCode, getStudentId, getStudentName, getToken, saveClassCode, saveStudentId } from '../utils/local-storage';

function useSetupSocket() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const reconnecting = useRef(false);
  const screenOffTimestampRef = useRef<number | null>(null);
  const screenOnTimestampRef = useRef<number | null>(null);
  const offScreenRef = useRef<string | null>(null);

  const screenOffTime = screenOffTimestampRef.current || 0;
  let screenOnTime = screenOnTimestampRef.current || 0;

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(API_URL, {
          secure: true,
          reconnection: true,
          rejectUnauthorized: false,
          closeOnBeforeunload: false,
          autoConnect: true,
          transports: ['websocket'],
        })
      );
    }
  }, [socket]);

  const reconnectWebSocket = async () => {
    if (reconnecting.current) return;
    reconnecting.current = true;

    if (navigator.onLine && socket) { // Check if socket is defined

      socket.connect();

      const classCode2 = getClassCode();
      const studentId2 = getStudentId();

      if (classCode2 && studentId2) {

        if (!socket.connected) {
          socket.connect();
        }

        socket.emit(EventsMessagesEnum.JoinRoom, {
          classCode: getClassCode(),
          studentId: getStudentId(),
          token: getToken(),
        });

      } else if (getStudentName()) {

        socket.emit(EventsMessagesEnum.JoinStudentToRoom, {
          name: getStudentName(),
          classCode: getClassCode(),
        });
      }
      else if (!socket) {
        // If socket is null, you might want to handle this case accordingly
        console.error('Socket is null');
      }
    }
    reconnecting.current = false;
  };

  useEffect(() => {
    if (socket) {
      socket.on(EventsMessagesEnum.Disconnect, () => {

        console.info('socket connection lost', `socket id ${socket?.id}`);

        socket.off(EventsMessagesEnum.Connect);
      });

      socket.on('reconnect', () => {
        console.log('Socket reconnected');
        // Set socket.connected to true on reconnect
        socket.connected = true;
      });


      socket.on(EventsMessagesEnum.JoinedRoom, (data: RoomParticipant) => {
        if (data?.clientId === socket.id && data?.classCode) {
          saveClassCode(data.classCode);
          if (data.userType === UserType.Student) {
            saveStudentId(data?.studentId);
          }
        }
      });

      socket.on(EventsMessagesEnum.Connect, () => {
        // eslint-disable-next-line no-console
        console.info('socket connected', `socket id ${socket.id}`);

        if (getToken()) {
          console.log("socket connect getToken method executed");
          socket.emit(EventsMessagesEnum.JoinedRoom, {
            classCode: getClassCode(),
            token: getToken(),
          });
        } else if (getStudentName()) {
          socket.emit(EventsMessagesEnum.JoinStudentToRoom, {
            name: getStudentName(),
            classCode: getClassCode(),
          });
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    return () => {
      if (socket) {
        console.log("socket terminate");
        socket.close();
        setSocket(null);
      }
    };
  }, [socket]);


  useEffect(() => {
    const handleVisibilityChange = async () => {

      const userAgent = navigator.userAgent;
      const isiPhone = /iPhone/.test(userAgent);
      if (isiPhone === true && socket) {
        socket.connected = false;
      }

      if (document.visibilityState === 'hidden' && getStudentId() && offScreenRef.current !== 'hidden') {
        offScreenRef.current = 'hidden';
        localStorage.setItem("OffTime", Date.now().toString())
      }

      let OffTimeLocal = localStorage.getItem("OffTime");
      const offTimeNumber = Number(OffTimeLocal);
      const durationOffTime = Date.now() - offTimeNumber;

      if (getStudentId()) {
        if (document.visibilityState === 'visible' && durationOffTime <= 7200 * 1000) {
          offScreenRef.current = 'visible'
          localStorage.setItem("OffTime", "");
          // if (socket) {
          //   socket.connected = true;
          // }
        }
      }

      OffTimeLocal = localStorage.getItem("OffTime")

      if (OffTimeLocal !== null && !isNaN(Number(OffTimeLocal))) {
        if ((document.visibilityState === 'visible' && !socket?.connected && durationOffTime <= 7200 * 1000) || (document.visibilityState === 'visible' && isiPhone && durationOffTime <= 7200 * 1000)) {
          screenOnTimestampRef.current = Date.now();
          localStorage.setItem("OffTime", "")
          offScreenRef.current = 'visible'
          await reconnectWebSocket();
        }
      }
    };

    const handleUserActivity = () => {
      if (document.visibilityState === 'visible') {
        screenOnTimestampRef.current = Date.now();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [socket, document.visibilityState]);

  useEffect(() => {
    const checkInactivity = async () => {
      const now = Date.now();
      // screenOffTime = screenOffTimestampRef.current || 0;
      screenOnTime = screenOnTimestampRef.current || 0;
      const localStorageKeys = Object.keys(localStorage);
      const data = localStorageKeys.map((key) => ({
        key: key,
        value: localStorage.getItem(key),
      }));
      const studentIdData = data.find((item) => item.key === 'studentId');
      const studentId = studentIdData ? studentIdData.value : undefined;

      const commentData = data.find((item) => item.key === 'comment');
      const commentFinal = commentData ? commentData.value : undefined;

      const classCodeData = data.find((item) => item.key === 'classCode');
      const classCodeFinal = classCodeData ? classCodeData.value : undefined;

      if (socket && studentId) {

        if (document.visibilityState === 'visible' && studentId) {

          if (now - screenOnTime >= 14400 * 1000 && screenOnTime != 0) {

            socket.emit(EventsMessagesEnum.CancelAssistanceRequest, {
              comment: commentFinal,
              classCode: classCodeFinal
            });
          }
          const OffTimeLocal = localStorage.getItem("OffTime");

          if (OffTimeLocal !== null && !isNaN(Number(OffTimeLocal))) {
            const offTimeNumber = Number(OffTimeLocal);
            if (!isNaN(offTimeNumber) && now - offTimeNumber >= 7200 * 1000 && offTimeNumber !== 0) {
              await reconnectWebSocket();

              setTimeout(() => {
                socket.emit(EventsMessagesEnum.CancelAssistanceRequest, {
                  comment: commentFinal,
                  classCode: classCodeFinal
                });
              }, 2000)
              localStorage.setItem("OffTime", "")
            }
          }
        }
      }
    };
    const intervalId = setInterval(checkInactivity, 5000); // Check every 3 second
    return () => {
      clearInterval(intervalId);
    };
  }, [socket]);

  // useEffect(() => {
  //   // Try to reconnect the socket when the component first mounts
  //   reconnectWebSocket();
  // }, []);

  return socket;
}

export default useSetupSocket;